<template>

  <header class="p-3 bg-primary text-white">
    <div class="d-flex align-items-center">
      <svg width="40" height="40"><use xlink:href="#log"></use></svg>
      <div class="lh-1 mx-2">
        <h1 class="h6 mb-0 text-white lh-1 fw-bold">これまでの記録</h1>
      </div>
    </div>
  </header>

  <main class="p-3">

    <nav id="range-select">
      <label>日付</label>
      <div>
        <input
          type="date"
          class="form-control"
          :max="today"
          v-model="from"
          @change="setFrom"
        >
      </div>
      <span>〜</span>
      <div>
        <input
          type="date"
          class="form-control"
          :max="today"
          v-model="to"
          @change="setTo"
        >
      </div>
    </nav>


    <table class="table table-striped table-hover logtable">
      <thead>
        <tr>
          <th class="index">日時/分類/担当</th>
          <th class="content">内容</th>
          <th class="delete">削除</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="log in logList"
          :key="log.id"
        >
          <td class="index">
            <div class="date">{{log.date}}</div>
            <div class="genre">{{log.genre}}</div>
            <div class="tantou">{{log.tantou}}</div>
            <div class="target">
              <router-link :to="{name: 'AnimalDetail', params: {id: log.animal_id}}">
                {{log.who}}
              </router-link>
            </div>
          </td>
          <td class="content">{{log.content}}</td>
          <td class="delete">
            <button
              class="btn btn-secondary btn-sm"
              :value="log.id"
              @click="deleteLog"
            >×</button>
          </td>
        </tr>
      </tbody>
    </table>
  </main>

</template>

<script>
import axios from "axios"
export default {
  data() {
    return {
      from: "",
      to: "",
      logList: []
    }
  },
  mounted() {
    let today = new Date()
    this.to = "" + today.getFullYear() + "-" + ('0' + (today.getMonth()+1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2)
    today.setDate(today.getDate() - 7);
    this.from = "" + today.getFullYear() + "-" + ('0' + (today.getMonth()+1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2)

    this.dateQuery()
    // axios
    //   .get("/wp-json/pasti/v1/animal/log")
    //   .then((response) => {
    //     this.logList = response.data
    //   })
  },
  methods: {
    setFrom(date) {
      this.from = date.target.value
      this.dateQuery()
    },
    setTo(date) {
      this.to = date.target.value
      this.dateQuery()
    },
    dateQuery(){
      axios
        .post("/wp-json/pasti/v1/animal/log",{
          from: this.from,
          to: this.to
        })
        .then((response) => {
          this.logList = response.data
        })
      // axios
      //   .post("/wp-json/pasti/v1/log/attendance/range", {
      //     from: this.from,
      //     to: this.to
      //   })
      //   .then( response => {
      //     console.log(response)
      //     this.$emit("updateList", response.data)
      //   })
      //   .catch( error => {
      //     console.log(error);
      //   })
    },
    deleteLog(e) {
      if( confirm("削除してよろしいですか？") ){
        this.$store.dispatch('AnimalLog/delete', {
          id: e.target.value
        })
        let newList = this.logList.slice()
        newList = newList.filter( ({id}) => id !== e.target.value )
        this.logList = newList
      }
    }
  },
  computed: {
    today() {
      let today = new Date()
      today = "" + today.getFullYear() + "-" + ('0' + (today.getMonth()+1)).slice(-2) + "-" + ('0' + today.getDate()).slice(-2)
      console.log(today)
      return today
    },
    linkto(id) {
      return "/kennel/" + id
    }
  }
}
</script>

<style scoped>
.date,
.animal,
.genre {
  width: 170px
}
.delete {
  width: 60px;
  text-align: center;
}
</style>